import { FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { deepmergeInto } from "deepmerge-ts";
import { KinesenseApiResponse } from "./kinesense";

export async function fetchAllPages<T>(
    fetchWithBQ: (arg: string | FetchArgs) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, unknown>>,
    url: string,
): Promise<{ data?: T; error?: FetchBaseQueryError }> {
    // First fetch, get base data which will be merged with subsequent pages
    const result = await fetchWithBQ(url);
    if (result.error) {
        return { error: result.error as FetchBaseQueryError };
    }
    const response = result as KinesenseApiResponse<T>;

    const merged = { data: result.data as T };

    // If there are multiple pages, fetch them one by one and merge all the data
    let hasNextPage = response.hasNextPage ?? false;
    let nextPageKey = response.pageKey;
    while (hasNextPage && nextPageKey) {
        const nextResult = await fetchWithBQ(`${url}?pageKey=${nextPageKey}`);
        console.log(nextResult);
        if (nextResult.error) {
            return { error: nextResult.error as FetchBaseQueryError };
        }
        const nextResponse = nextResult as KinesenseApiResponse<T>;

        deepmergeInto(merged, nextResponse);

        hasNextPage = nextResponse.hasNextPage ?? false;
        nextPageKey = nextResponse.pageKey;
    }

    return merged;
}
