import {
    AnimalTagObject,
    GroupTagObject,
    LocationTagObject,
    TagObjectType,
    TagObjectTypes,
    VehicleTagObject,
    VehicleTagObjectTypes,
    VideoFrameTagObjectImage,
} from "cloud-core/tags/TagObject";
import { useRef, useState } from "react";
import { Button, FloatingLabel, Form, FormControlProps, Modal } from "react-bootstrap";
import SpinnerButton from "../../../components/SpinnerButton";
import { TagObject } from "../../../models/tags/TagObject";
import {
    useCreateTagObjectMutation,
    useGetMediaSourceQuery,
    useUpdateTagObjectMutation,
} from "../../../store/api/kinesense";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import { capitaliseFirst, tryExtractErrorMessage } from "../../../utilities/helpers";
import "./EditTagObjectModal.scss";
import EditTagObjectModalAliasesField from "./EditTagObjectModalAliasesField";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";

function renderField(
    name: string,
    label: string,
    placeholder: string,
    defaultValue: string | number,
    fieldProps?: React.HTMLProps<HTMLInputElement> & FormControlProps,
) {
    const isRequired = fieldProps?.required ?? false;
    fieldProps = fieldProps ?? {};
    label = isRequired ? `${label}*` : label;
    return (
        <FloatingLabel key={name} id={name} controlId={name} label={label}>
            <Form.Control {...fieldProps} defaultValue={defaultValue} name={name} placeholder={placeholder} />
        </FloatingLabel>
    );
}

function renderFieldsForType(type: TagObjectType, tagObject: TagObject | undefined) {
    let result = null;
    switch (type) {
        case "person":
            // `Aliases` field handled separately
            break;
        case "vehicle":
            {
                const t = tagObject as VehicleTagObject<number>;
                result = (
                    <>
                        {renderField(
                            "registrationPlate",
                            "Registration plate",
                            "Registration plate of the tagged vehicle",
                            t?.registrationPlate ?? "",
                        )}
                        {renderField("make", "Make", "Make of the tagged vehicle", t?.make ?? "")}
                        {renderField("model", "Model", "Model of the tagged vehicle", t?.model ?? "")}
                        {renderField("colour", "Colour", "Colour of the tagged vehicle", t?.colour ?? "")}
                        <FloatingLabel controlId="vehicleType" label="Vehicle Type">
                            <Form.Select defaultValue={t?.vehicleType ?? "car"} name="vehicleType" required>
                                {Object.entries(VehicleTagObjectTypes).map(([type, label]) => (
                                    <option value={type} key={type}>
                                        {label}
                                    </option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </>
                );
            }
            break;
        case "group":
            {
                const t = tagObject as GroupTagObject<number>;
                result = renderField(
                    "approxSize",
                    "Approximate size",
                    "Approximate size of the tagged group",
                    t?.approxSize ?? 1,
                    {
                        type: "number",
                        min: 1,
                    },
                );
            }
            break;
        case "animal":
            {
                const t = tagObject as AnimalTagObject<number>;
                result = (
                    <>
                        {renderField("species", "Species", "Species of the tagged animal", t?.species ?? "")}
                        {renderField("breed", "Breed", "Breed of the tagged animal", t?.breed ?? "")}
                        {renderField("colour", "Colour", "Colour of the tagged animal", t?.colour ?? "")}
                    </>
                );
            }
            break;
        case "location": {
            const t = tagObject as LocationTagObject<number>;
            result = (
                <>
                    {renderField("address", "Address", "Address of the tagged location", t?.address ?? "", {
                        required: true,
                    })}
                    {renderField("postCode", "Post code", "Post code of the tagged location", t?.postCode ?? "")}
                    {renderField("latitude", "Latitude", "Latitude of the tagged location", t?.latitude ?? "", {
                        inputMode: "numeric",
                        pattern: "[0-9]+[.][0-9]+",
                        maxLength: 19,
                    })}
                    {renderField("longitude", "Longitude", "Longitude of the tagged location", t?.latitude ?? "", {
                        inputMode: "numeric",
                        pattern: "[0-9]+[.][0-9]+",
                        maxLength: 19,
                    })}
                </>
            );
        }
    }
    return result;
}

export interface EditTagObjectModalProps {
    tagObject?: TagObject;
    selection?: AreaBounds;
    context: VizContext;
}

const EditTagObjectModal = NiceModal.create((props: EditTagObjectModalProps) => {
    const { view, projectId } = props.context;
    const baseEntity = view?.selectedEntity ?? view?.filteredEntities[0];
    const mediaId = baseEntity?.sourceObject?.mediaSource?.mediaId;

    const { data: media } = useGetMediaSourceQuery(
        { projectId, mediaId },
        { skip: projectId === undefined || mediaId === undefined },
    );
    const fileId = media?.files?.display?.fileId;

    const formRef = useRef(null);
    const [type, setObjectType] = useState<TagObjectType>(props.tagObject?.type ?? "person");
    const [aliases, setAliases] = useState<string[]>(
        props.tagObject?.type === "person" ? props.tagObject?.aliases ?? [] : [],
    );

    // Whether the modal should be used to merely update an existing tag object
    const shouldUpdate = props.tagObject !== undefined;

    const [requestCreateTagObject, createTagObjectResult] = useCreateTagObjectMutation();
    const [requestUpdateTagObject, updateTagObjectResult] = useUpdateTagObjectMutation();

    const modal = useModal();
    modal.keepMounted = true;

    function updateTagObject(newTagObject: Partial<TagObject>) {
        // Fill blank fields to override existing object's fields
        for (const key in props.tagObject) {
            if (newTagObject[key] === undefined) {
                switch (typeof props.tagObject[key]) {
                    case "string":
                        newTagObject[key] = "";
                        break;
                    case "number":
                        newTagObject[key] = 0;
                        break;
                    case "object":
                        newTagObject[key] = {};
                        break;
                }
            }
        }

        requestUpdateTagObject({ projectId, tagObjectId: props.tagObject.objectId, tagObject: newTagObject })
            .unwrap()
            .then((obj) => {
                Notifications.notify(
                    "Tag Object updated",
                    `The tag object with name "${props.tagObject.name}" has been updated successfully`,
                );
                modal.resolve(obj);
                modal.hide();
            })
            .catch((e) => {
                Notifications.notify(
                    "Error updating tag object",
                    `The following error was encountered while attempting to update an existing tag object: "${tryExtractErrorMessage(
                        e,
                    )}"`,
                );
            });
    }

    function createNewTagObject(newTagObject: Partial<TagObject>) {
        requestCreateTagObject({ projectId, tagObject: newTagObject })
            .unwrap()
            .then((obj) => {
                Notifications.notify("Tag Object created", "A new Tag Object was created successfully");
                modal.resolve(obj);
                modal.hide();
            })
            .catch((e) => {
                Notifications.notify(
                    "Error creating tag object",
                    `The following error was encountered while attempting to create a new tag object: "${tryExtractErrorMessage(
                        e,
                    )}"`,
                );
            });
    }

    function handleOnSubmit(e: React.FormEvent) {
        e.preventDefault();

        const data = new FormData(formRef.current);
        const newTagObject: Partial<TagObject> = {};

        const entries = data.entries();
        let curr = entries.next();

        while (!curr.done) {
            const [key, value] = curr.value;
            if (value !== "") {
                newTagObject[key] = value;
            }
            curr = entries.next();
        }

        // Special case since `aliases` is a list of values
        if (type === "person") {
            newTagObject["aliases"] = aliases;
        }

        if (media && fileId) {
            // Image representing a section of a frame of the current video
            const videoImage: VideoFrameTagObjectImage = {
                type: "frame",
                fileId,
                timestamp: (view?.cursor ?? media.startsAt) - media.startsAt,
                bounds: props.selection ?? [0, 0, 1, 1],
            };
            newTagObject.images = [videoImage];
        }

        if (shouldUpdate) {
            updateTagObject(newTagObject);
        } else {
            createNewTagObject(newTagObject);
        }
    }

    return (
        <Modal {...bootstrapDialog(modal)} centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>New Tag Object</Modal.Header>
            <Form className="create-tag-object-modal-form" onSubmit={handleOnSubmit} ref={formRef}>
                <Modal.Body className="gap-2 d-flex flex-column">
                    <FloatingLabel controlId="type" label="Type">
                        <Form.Select
                            name="type"
                            value={type}
                            onChange={(e) => setObjectType(e.target.value as TagObjectType)}
                            required
                        >
                            {TagObjectTypes.map((type) => (
                                <option value={type} key={type}>
                                    {capitaliseFirst(type)}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                    {renderField("name", "Name", "Name for the Tag Object", props.tagObject?.name ?? "", {
                        required: true,
                    })}
                    {renderField("comments", "Comments", "Leave a comment here", props.tagObject?.comments ?? "", {
                        as: "textarea",
                    })}
                    {renderFieldsForType(type, props.tagObject)}
                    {type == "person" && <EditTagObjectModalAliasesField aliases={aliases} setAliases={setAliases} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.hide}>
                        Cancel
                    </Button>
                    <SpinnerButton
                        isLoading={createTagObjectResult?.isLoading || updateTagObjectResult?.isLoading}
                        label={shouldUpdate ? "Update" : "Create"}
                        buttonProps={{ type: "submit", variant: "success", style: { width: "5rem" } }}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default EditTagObjectModal;
